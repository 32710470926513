"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDControlsComponentFactory = void 0;
var StringInputCustomComponent_1 = require("../custom-components/StringInputCustomComponent");
var BooleanInputCustomComponent_1 = require("../custom-components/BooleanInputCustomComponent");
var Vector3CustomComponent_1 = require("../custom-components/Vector3CustomComponent");
var ButtonCustomComponent_1 = require("../custom-components/ButtonCustomComponent");
var ChecklistCustomComponent_1 = require("../custom-components/ChecklistCustomComponent");
var ColorCustomComponent_1 = require("../custom-components/ColorCustomComponent");
var DropdownCustomComponent_1 = require("../custom-components/DropdownCustomComponent");
var FileCustomComponent_1 = require("../custom-components/FileCustomComponent");
var ModalCustomComponent_1 = require("../custom-components/ModalCustomComponent");
var SliderCustomComponent_1 = require("../custom-components/SliderCustomComponent");
var GroupCustomComponent_1 = require("../custom-components/GroupCustomComponent");
var StaticHTMLCustomComponent_1 = require("../custom-components/StaticHTMLCustomComponent");
var StaticHTMLSettingComponent_1 = require("../settings-components/StaticHTMLSettingComponent");
var SliderParameterComponent_1 = require("../parameters-components/SliderParameterComponent");
var FileParameterComponent_1 = require("../parameters-components/FileParameterComponent");
var BooleanInputParameterComponent_1 = require("../parameters-components/BooleanInputParameterComponent");
var ButtonParameterComponent_1 = require("../parameters-components/ButtonParameterComponent");
var ColorParameterComponent_1 = require("../parameters-components/ColorParameterComponent");
var StringInputParameterComponent_1 = require("../parameters-components/StringInputParameterComponent");
var DropDownParameterComponent_1 = require("../parameters-components/DropDownParameterComponent");
var ChecklistParameterComponent_1 = require("../parameters-components/ChecklistParameterComponent");
var DropdownSettingComponent_1 = require("../settings-components/DropdownSettingComponent");
var SliderSettingComponent_1 = require("../settings-components/SliderSettingComponent");
var CheckboxSettingComponent_1 = require("../settings-components/CheckboxSettingComponent");
var ColorSettingComponent_1 = require("../settings-components/ColorSettingComponent");
var LoadLightSceneDropdownSettingComponent_1 = require("../settings-components/lights/LoadLightSceneDropdownSettingComponent");
var LightDefinitionSettingComponent_1 = require("../settings-components/lights/LightDefinitionSettingComponent");
var GroupSettingComponent_1 = require("../settings-components/GroupSettingComponent");
var SliderLightPropertySettingComponent_1 = require("../settings-components/lights/SliderLightPropertySettingComponent");
var ColorLightPropertySettingComponent_1 = require("../settings-components/lights/ColorLightPropertySettingComponent");
var Vector3LightPropertySettingComponent_1 = require("../settings-components/lights/Vector3LightPropertySettingComponent");
var Vector3SettingComponent_1 = require("../settings-components/Vector3SettingComponent");
var CheckboxLightPropertySettingComponent_1 = require("../settings-components/lights/CheckboxLightPropertySettingComponent");
var AddLightModalSettingComponent_1 = require("../settings-components/lights/AddLightModalSettingComponent");
var SaveLightSceneModalSettingComponent_1 = require("../settings-components/lights/SaveLightSceneModalSettingComponent");
var CameraDropdownSettingComponent_1 = require("../settings-components/camera/CameraDropdownSettingComponent");
var StringInputSettingComponent_1 = require("../settings-components/StringInputSettingComponent");
/**
 * The controls component factory.
 */
var SDControlsComponentFactory = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    /**
     * The controls component factory constructor.
     */
    function SDControlsComponentFactory(_api, _queue, _domManager, _tinyColorUtil, _controlEvents, _fileHelper, _sdSettings, _groups, _dragControls) {
        this._api = _api;
        this._queue = _queue;
        this._domManager = _domManager;
        this._tinyColorUtil = _tinyColorUtil;
        this._controlEvents = _controlEvents;
        this._fileHelper = _fileHelper;
        this._sdSettings = _sdSettings;
        this._groups = _groups;
        this._dragControls = _dragControls;
        this._settingDestoryEvents = [];
        // #region Properties (1)
        this._settingCreatedEvents = [];
    }
    Object.defineProperty(SDControlsComponentFactory.prototype, "groups", {
        // #endregion Constructors (1)
        get: function () {
            return this._groups;
        },
        enumerable: false,
        configurable: true
    });
    // #region Public Methods (4)
    SDControlsComponentFactory.prototype.createShapeDiverParameter = function (parameter, container, editMode) {
        if (!editMode && parameter.hidden)
            return null;
        var parameterComponent = null;
        switch (this.resolveParameterType(parameter)) {
            case "button" /* BUTTON */:
                parameterComponent = new ButtonParameterComponent_1.ButtonParameterComponent(this._api, this._domManager, this._queue, container, parameter, editMode);
                break;
            case "checkbox" /* CHECKBOX */:
                parameterComponent = new BooleanInputParameterComponent_1.BooleanInputParameterComponent(this._api, this._domManager, this._queue, container, parameter, editMode);
                break;
            case "checklist" /* CHECKLIST */:
                parameterComponent = new ChecklistParameterComponent_1.ChecklistParameterComponent(this._api, this._domManager, this._queue, container, parameter, editMode);
                break;
            case "color" /* COLOR */:
                parameterComponent = new ColorParameterComponent_1.ColorParameterComponent(this._api, this._domManager, this._queue, container, parameter, this._tinyColorUtil, editMode, this._domManager.useSpectrum);
                break;
            case "dropdown" /* DROPDOWN */:
                parameterComponent = new DropDownParameterComponent_1.DropdownParameterComponent(this._api, this._domManager, this._queue, container, parameter, editMode);
                break;
            case "string" /* STRING */:
                parameterComponent = new StringInputParameterComponent_1.StringInputParameterComponent(this._api, this._domManager, this._queue, container, parameter, editMode);
                break;
            case "file" /* FILE */:
                parameterComponent = new FileParameterComponent_1.FileParameterComponent(this._api, this._domManager, this._queue, container, parameter, this._fileHelper, editMode);
                break;
            case "slider" /* SLIDER */:
                parameterComponent = new SliderParameterComponent_1.SliderParameterComponent(this._api, this._domManager, this._queue, container, parameter, editMode);
                break;
        }
        if (parameterComponent === null) {
            throw new Error("Cannot create parameter component of type " + parameter.elementType);
        }
        if (parameter.hidden) {
            parameterComponent.hidden = true;
        }
        return parameterComponent;
    };
    SDControlsComponentFactory.prototype.createShapeDiverSetting = function (settingDefinition, container) {
        var type = settingDefinition.type;
        var setting = null;
        switch (type) {
            case "camera" /* CAMERA */:
                setting = new CameraDropdownSettingComponent_1.CameraDropdownSettingComponent(this._api, settingDefinition, container, this, this._controlEvents);
                break;
            case "checkbox" /* CHECKBOX */:
                setting = new CheckboxSettingComponent_1.CheckboxSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "color" /* COLOR */:
                setting = new ColorSettingComponent_1.ColorSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "dropdown" /* DROPDOWN */:
                setting = new DropdownSettingComponent_1.DropdownSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "group" /* GROUP */:
                setting = new GroupSettingComponent_1.GroupSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "slider" /* SLIDER */:
                setting = new SliderSettingComponent_1.SliderSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "string" /* STRING */:
                setting = new StringInputSettingComponent_1.StringInputSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "statichtml" /* STATICHTML */:
                setting = new StaticHTMLSettingComponent_1.StaticHTMLSettingComponent(this._api, settingDefinition, container, this);
                break;
            case "vector3" /* VECTOR3 */:
                setting = new Vector3SettingComponent_1.Vector3SettingComponent(this._api, settingDefinition, container, this);
                break;
            case "light_slider" /* LIGHT_SLIDER */:
                setting = new SliderLightPropertySettingComponent_1.SliderLightPropertySettingComponent(this._api, settingDefinition, container, this, this._dragControls);
                break;
            case "light_color" /* LIGHT_COLOR */:
                setting = new ColorLightPropertySettingComponent_1.ColorLightPropertySettingComponent(this._api, settingDefinition, container, this, this._dragControls);
                break;
            case "light_checkbox" /* LIGHT_CHECKBOX */:
                setting = new CheckboxLightPropertySettingComponent_1.CheckboxLightPropertySettingComponent(this._api, settingDefinition, container, this, this._dragControls);
                break;
            case "light_vector" /* LIGHT_VECTOR3 */:
                setting = new Vector3LightPropertySettingComponent_1.Vector3LightPropertySettingComponent(this._api, settingDefinition, container, this, this._dragControls);
                break;
            case "light_definition" /* LIGHT_DEFINITION */:
                setting = new LightDefinitionSettingComponent_1.LightDefinitionSettingComponent(this._api, settingDefinition, container, this._domManager, this._queue, this, this._dragControls);
                break;
            case "load_light_scene" /* LOAD_LIGHT_SCENE */:
                setting = new LoadLightSceneDropdownSettingComponent_1.LoadLightSceneDropdownSettingComponent(this._api, settingDefinition, container, this, this._controlEvents, this._sdSettings);
                break;
            case "add_light_modal" /* ADD_LIGHT_MODAL */:
                setting = new AddLightModalSettingComponent_1.AddLightModalSetting(this._api, settingDefinition, container, this, this._sdSettings, this._controlEvents, this._dragControls);
                break;
            case "save_light_scene_modal" /* SAVE_LIGHT_SCENE_MODAL */:
                setting = new SaveLightSceneModalSettingComponent_1.SaveLightSceneModalSettingComponent(this._api, settingDefinition, container, this, this._sdSettings, this._controlEvents, this._dragControls);
                break;
        }
        if (setting == null)
            throw new Error("Cannot create setting component of type " + settingDefinition.type);
        this._settingCreatedEvents.forEach(function (x) { return x(setting, settingDefinition); });
        return setting;
    };
    /**
     * Create the user defined parameter.
     * @param { ControlParameterDefinition } parameterDefition
     */
    SDControlsComponentFactory.prototype.createUserDefinedParameter = function (parameterDefition, container, editMode) {
        var type = parameterDefition.type;
        var id = this.generateUUID();
        var customComponent = this.createCustomInputComponent(id, type, parameterDefition, container, editMode);
        if (customComponent) {
            return customComponent;
        }
        throw new Error("Cannot create input component of type  " + parameterDefition.type);
    };
    SDControlsComponentFactory.prototype.onSettingCreated = function (callback) {
        this._settingCreatedEvents.push(callback);
    };
    // #endregion Public Methods (4)
    // #region Private Methods (3)
    SDControlsComponentFactory.prototype.createCustomInputComponent = function (id, type, parameterDefition, container, editMode) {
        switch (type) {
            case "string" /* STRING */:
                return new StringInputCustomComponent_1.StringInputCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "checkbox" /* CHECKBOX */:
                return new BooleanInputCustomComponent_1.BooleanInputCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "vector3" /* VECTOR3 */:
                return new Vector3CustomComponent_1.Vector3CustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "button" /* BUTTON */:
                return new ButtonCustomComponent_1.ButtonCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "checklist" /* CHECKLIST */:
                return new ChecklistCustomComponent_1.ChecklistCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "group" /* GROUP */:
                return new GroupCustomComponent_1.GroupCustomComponent(id, parameterDefition, container, this._domManager, this._queue, this, editMode);
            case "color" /* COLOR */:
                return new ColorCustomComponent_1.ColorCustomComponent(id, parameterDefition, container, this._domManager, this._queue, this._domManager.useSpectrum, this._tinyColorUtil);
            case "dropdown" /* DROPDOWN */:
                return new DropdownCustomComponent_1.DropdownCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "file" /* FILE */:
                return new FileCustomComponent_1.FileCustomComponent(id, parameterDefition, container, this._domManager, this._queue, this._fileHelper, editMode);
            case "modal" /* MODAL */:
                return new ModalCustomComponent_1.ModalCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "slider" /* SLIDER */:
                return new SliderCustomComponent_1.SliderCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
            case "statichtml" /* STATICHTML */:
                return new StaticHTMLCustomComponent_1.StaticHTMLCustomComponent(id, parameterDefition, container, this._domManager, this._queue, editMode);
        }
    };
    /**
     * Create unique id.
     * @returns { string }
     */
    SDControlsComponentFactory.prototype.generateUUID = function () {
        var d = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };
    SDControlsComponentFactory.prototype.resolveParameterType = function (parameter) {
        if (parameter.elementType) {
            return parameter.elementType;
        }
        else if (!parameter.isParameter) {
            return "button" /* BUTTON */;
        }
        else if (parameter.visualization) {
            switch (parameter.visualization.toLowerCase()) {
                case "dropdown":
                case "cycle":
                    return "dropdown" /* DROPDOWN */;
                case "checklist":
                    return "checklist" /* CHECKLIST */;
                case "dial":
                case "slider":
                    return "slider" /* SLIDER */;
                default:
                    return parameter.visualization.toLowerCase();
            }
        }
        else if (parameter.type) {
            switch (parameter.type.toLowerCase()) {
                case "bool":
                    return "checkbox" /* CHECKBOX */;
                case "stringlist":
                    return "dropdown" /* DROPDOWN */;
                case "download":
                case "email":
                    return "button" /* BUTTON */;
                case "int":
                    return "slider" /* SLIDER */;
                default:
                    return parameter.type.toLowerCase();
            }
        }
    };
    return SDControlsComponentFactory;
}());
exports.SDControlsComponentFactory = SDControlsComponentFactory;
