/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *GlobalUtil.js*
 *
 * ### Content
 *   * commonly shared utility functionality
 *
 * @module GlobalUtils
 * @author Alex Schiftner <alex@shapediver.com>
 */

const TOTINYCOLOR = require('./toTinyColor'),
      THREE = require('../../externals/three'),
      ATOBTOA = require('../util/atobtoa'),
      CopyManagerInstance = require('./CopyManager').CopyManagerInstance,
      FileUtilInstance = require('./FileUtil').FileUtilInstance;

////////////
////////////
//
// Commonly shared utility functionality
//
////////////
////////////

/**
 * Definition of global utility functionality
 * @class GlobalUtils
 */
var GlobalUtils = function() {

  let that = this;

  let runningInIE = typeof window !== 'undefined' && window.navigator && window.navigator.userAgent.indexOf('Trident') > -1;
  let runningInBrowser = runningInIE ||
      (typeof document !== 'undefined'
          && typeof document.getElementById === 'function'
          && window
          && typeof window.Event === 'function'
      );
  let embeddingOrigin;
  let runningInIframe = false;

  if (runningInBrowser) {
    // in case we are running in an iframe, parent and window are different, in
    // that case we use the referrer
    runningInIframe = parent !== window;
    embeddingOrigin = runningInIframe ? document.referrer : window.location.origin;
  } else {
    embeddingOrigin = 'direct';
  }

  /**
   * Check if we are running in internet explorer (arrrggghhhh!!!!)
   */
  this.runningInIE = function() {
    return runningInIE;
  };

  /**
   * Check if we are running in a browser
   */
  this.runningInBrowser = function() {
    return runningInBrowser;
  };

  /**
   * Check if we are running in an iframe
   */
  this.runningInIframe = function() {
    return runningInIframe;
  };

  /**
   * Get guessed origin of embedding website
   */
  this.embeddingOrigin = function() {
    return embeddingOrigin + '';
  };

  /**
  * Helper functionality for getting attributes from DOM node
  *
  * @param {Element} node
  * @param {String} name - name of attribute
  * @param {*} defVal - default value to use if attribute not found
  * @return {*} attribute value
  */
  this.getNodeAttribute = function(node, name, defVal) {
    if (runningInBrowser && node && !Array.isArray(node) && typeof node.hasAttribute === 'function' && node.hasAttribute(name)) {
      return node.getAttribute(name);
    }
    return defVal;
  };

  /**
  * Helper functionality for getting boolean attributes from DOM node
  *
  * @param {Element} node
  * @param {String} name - name of attribute
  * @param {Boolean} defVal - default value to use if attribute not found
  * @return {Boolean} attribute value
  */
  this.getBooleanNodeAttribute = function(node, name, defVal) {
    if (runningInBrowser && node && !Array.isArray(node) && typeof node.hasAttribute === 'function' && node.hasAttribute(name)) {
      return this.toBoolean(node.getAttribute(name), defVal);
    }
    return defVal;
  };

  /**
   * Parse a query string to an object of key value pairs.
   *
   * The query string typically is read from the window object using window.location.search.substring(1)
   *
   * @param {String} query - the query string
   * @param {Boolean} [allowMultiple=false] - if true, and a key appears multiple times in the query string,
   *                                          return an array of values, if false the first value is returned,
   *                                          later values are ignored
   * @return {Object} object containing key value pairs
   */
  this.parseQueryString = function(query, allowMultiple) {
    allowMultiple = allowMultiple === undefined ? false : allowMultiple;
    let vars = query.split('&');
    let query_string = {};
    for (var i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === 'undefined') {
        query_string[key] = value;
      } else if (allowMultiple) {
        // If second entry with this name
        if (typeof query_string[key] === 'string') {
          let arr = [query_string[key], value];
          query_string[key] = arr;
          // If third or later entry with this name
        } else {
          query_string[key].push(value);
        }
      }
    }
    return query_string;
  };

  /**
   * Try to guess a mime type from a file name
   * @param {String} filename
   * @return {String} guessed mime type, empty string in case none could be guessed
   */
  this.guessMimeTypeFromFilename = function(filename) {
    return FileUtilInstance.guessMimeTypeFromFilename(filename);
  };

  /**
   * Check whether in a browser either of (window.)localStorage or (window.)sessionStorage
   * is both supported and available
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
   * @param {String} type - either 'localStorage' or 'sessionStorage'
   * @return {Boolean} true if localStorage resp sessionStorage is both supported and available
   */
  this.storageAvailable = function(type) {
    try {
      var storage = window[type],
          x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch(e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
  };

  /**
   * Check whether all entries of an array are of a certain type
   * @param {Array} a - Array whose entries should be type-checked
   * @param {String} type - type to check for (typeof)
   * @return {Boolean} true if all array entries are of given type
   */
  this.isArrayOfType = function(a, type) {
    if (!Array.isArray(a)) return false;
    return a.findIndex( function(elem) {
      return (typeof elem !== type);
    }) === -1;
  };


  /**
   * Replacer function for creating deep copies using JSON.parse and JSON.stringify
   * Replaces certain values which would otherwise get lost
   * @param {String} key - key to stored
   * @param {Object} value - value to be stored
   * @return {Object} Replaced or original value
   */
  this.deepCopyReplacer = function(key, value) {
    return CopyManagerInstance.deepCopyReplacer(key, value);
  };

  /**
   * Reviver function for creating deep copies using JSON.parse and JSON.stringify
   * Restores certain values which would otherwise get lost
   * @param {String} key - key to restored
   * @param {Object} value - value to be restored
   * @return {Object} Restored value
   */
  this.deepCopyReviver = function(key, value) {
    return CopyManagerInstance.deepCopyReviver(key, value);
  };

  /**
   * Helper function for creating deep copies
   * @param {Object} o - Object to create a deep copy of
   * @param {String[]} [assign] - optional array of property names which should not be copied but assigned
   * @return {Object} Deep copy of o
   */
  this.deepCopy = function(o, assign) {
    return CopyManagerInstance.deepCopy(o, assign);
  };

  /**
   * Helper function to set value if undefined
   * @param {Object} o - Object which should be set if it is undefined
   * @param {Object} val - Set o to this value if it is undefined
   * @return {Object} Returns o, i.e. it's existing value if it was defined already
   */
  this.setIfUndefined = function(o, val) {
    o = (typeof o === 'undefined') ? val : o;
    return o;
  };

  /**
   * Helper function to inject own enumerable properties of one object into another.
   *
   * Typically used to inject function definitions returned from a closure.
   * @param {Object} source - Source object whose own enumerable properties shall be injected to destination object
   * @param {Object} dest - Destination object
   * @param {Boolean} [force=true] - true: overwrite existing properties in dest, false: keep existing properties in dest
   * @return {Object} dest after modifications
   */
  this.inject = function(source, dest, force) {
    if ( force === undefined ) force = true;
    Object.keys(source).forEach( function(key) {
      if ( force || !dest.hasOwnProperty(key) ) {
        dest[key] = source[key];
      }
    });
    return dest;
  };

  /**
   * Define a property that will not be found by the typical functionality
   * listing the properties of an object such ad for..in loops.
   *
   * @param  {String} key The property identifier, customarily begins with a '_'
   * @param  {*} val Initial value of the property
   */
  this.defineHiddenProperty = function(key, val) {
    Object.defineProperty(this, key, {
      enumerable: false,
      configurable: false,
      writable: true,
      value: val
    });
  };

  /**
   * Define a property that can not be changed by regular means
   *
   * @param  {String} key The property identifier, customarily begins with a '_'
   * @param  {*} val Value of the property
   */
  this.defineConstantProperty = function(key, val) {
    Object.defineProperty(this, key, {
      enumerable: true,
      configurable: false,
      writable: false,
      value: val
    });
  };

  /**
   * Create a 32bit integer checksum for a string
   * @param  {String} str [description]
   * @return {Number}   [description]
   */
  this.stringChecksum = function(str) {
    var hash = 0,
        strlen = str.length,
        i,
        c;
    if (strlen === 0) {
      return hash;
    }
    for (i = 0; i < strlen; i++) {
      c = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + c;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };

  /**
   * Try to cast a number from a string value
   *
   * @param  {String} val - Input value which is to be converted to a number
   * @param  {Number} [defVal] - Default value to assume should conversion fail ()
   * @return {Number} converted value
   */
  this.toNumber = function(val, defVal) {
    let cv = Number(val);
    if (Number.isNaN(cv)) {
      cv = defVal;
    }
    return cv;
  };

  /**
   * Try to cast a boolean from input value
   *
   * @param  {String} bVal - Input value which is to be converted to a boolean
   * @param  {Boolean} [defVal=false] - Default value to assume should conversion fail
   * @return {Boolean} converted value
   */
  this.toBoolean = function(bVal, defVal) {
    if ( typeof defVal !== 'boolean' )
      defVal = false;

    if (typeof bVal === 'boolean') {
      return bVal;
    }
    else if (typeof bVal === 'string') {
      let lc = bVal.toLowerCase();
      if (defVal) {
        if (lc === 'false' || lc === '0') {
          return false;
        } else {
          return true;
        }
      } else {
        if (lc === 'true' || lc === '1') {
          return true;
        } else {
          return false;
        }
      }
    }
    else {
      return Boolean(bVal);
    }
  };

  /**
   * Create a kind of unique id string from a pseudo random number
   * @return {String}   unique id string
   */
  this.createRandomId = function() {
    return Math.random().toString(36).substring(2, 15);
  };

  /**
   * Get a nested element of an object
   * @param {Object} o - object to get nested element of
   * @param {String[]} pathArr - array defining path into the object, may contain strings for accessing properties or numbers for accessing array elements
   * @return {*}   nested element, undefined if not found
   */
  this.getAt = function(o, pathArr) {
    return CopyManagerInstance.getAt(o, pathArr);
  };

  /**
   * Get a nested element of an object
   * @param {Object} o - object to get nested element of
   * @param {String} path - path string
   * @return {*}   nested element, undefined if not found
   */
  this.getAtPath = function(o, path) {
    return CopyManagerInstance.getAtPath(o, path);
  };

  /**
   * Set a nested element of an object, if the element at the given path already exists (force=false), or if the path up to the element exists (force=true)
   * @param {Object} o - object to set nested element for
   * @param {String[]} pathArr - array of strings defining path into object
   * @param {*} v - new value to set
   * @param {Boolean} [force=false] - if path up to the element exists, but not the element, set it anyway
   * @return {*}   value of nested element, undefined if value was not set
   */
  this.setAt = function(o, pathArr, v, f) {
    let l = pathArr.pop();
    if (typeof l !== 'string') return;
    if (pathArr.length > 0) {
      o = pathArr.reduce(
        (obj, key) => (obj && obj[key] !== undefined) ? obj[key] : undefined,
        o
      );
    }
    if (o) {
      if ( o[l] !== undefined || f) {
        o[l] = v;
        return v;
      }
    }
  };

  /**
   * Set a nested element of an object, if the element at the given path already exists
   * @param {Object} o - object to set nested element for
   * @param {String} path - path string, may not contain array access
   * @param {*} v - new value to set
   * @param {Boolean} [force=false] - if path up to the element exists, but not the element, set it anyway
   * @return {*}   value of nested element, undefined if not set
   */
  this.setAtPath = function(o, path, v, f) {
    let pathArr = path.replace(/\]$/,'').split(/[.[\]]+/);
    return that.setAt(o,pathArr,v,f);
  };

  /**
   * Set a nested element of an object, potentially overwriting existing elements along the path
   * @param {Object} o - object to set nested element for
   * @param {String[]} pathArr - array of strings defining path into object
   * @param {*} v - new value to set
   * @return {*}   value of nested element, undefined if was not set
   */
  this.forceAt = function(o, pathArr, v) {
    return CopyManagerInstance.forceAt(o, pathArr, v);
  };

  /**
   * Set a nested element of an object, potentially overwriting existing elements along the path
   * @param {Object} o - object to set nested element for
   * @param {String} path - path string
   * @param {*} v - new value to set
   * @return {*}   value of nested element, undefined if was not set
   */
  this.forceAtPath = function(o, path, v) {
    return CopyManagerInstance.forceAtPath(o, path, v);
  };

  /**
   * Delete a nested element of an object
   * @param {Object} o - object to delete nested element for
   * @param {String[]} pathArr - array defining path into object, may contain strings for accessing properties or numbers for accessing array elements
   * @return {*} previous value of deleted element, undefined if did not exist
   */
  this.deleteAt = function(o, pathArr) {
    let l = pathArr.pop();
    if (typeof l !== 'string') return;
    if (pathArr.length > 0) {
      o = pathArr.reduce(
        (obj, key) => (obj && obj[key] !== undefined) ? obj[key] : undefined,
        o
      );
    }
    if (o) {
      let p = o[l];
      delete o[l];
      return p;
    }
  };

  /**
   * Delete a nested element of an object
   * @param {Object} o - object to delete nested element for
   * @param {String} path - path string
   * @return {*}   previous value of deleted element, undefined if did not exist
   */
  this.deleteAtPath = function(o, path) {
    let pathArr = path.replace(/\]$/,'').split(/[.[\]]+/);
    return that.deleteAt(o,pathArr);
  };

  /**
   * Given an object, return all paths to nested own enumerable properties which are leafs or arrays
   * Does not traverse object
   * @param {Object} o - object to get paths for
   * @param {String[]} paths - list of paths to add to, pass an empty array for first call
   * @param {String} [prefix=''] - prefix to use for paths, leave undefined for first call
   * @param {String[]} [leafNames=[]] - optional array of property names at which to stop traversal
   * @param {Boolean} [includePrototype] - if true, also return paths to enumerable properties in the prototype chain
   * @return {Boolean}   true if o is a leaf and its path should be added, false otherwise
   */
  this.getPaths = function(o, paths, prefix, leafNames, includePrototype) {
    return CopyManagerInstance.getPaths(o, paths, prefix, leafNames, includePrototype);
  };

  /**
   * Set defaults in an object
   * Defaults will be set recursively and will be deep-copied,
   * existing properties will not be overwritten if not forced,
   * defaults will be applied left to right if an array of defaults is given
   * @param {Object} o - object to set defaults in
   * @param {Object|Object[]} d - defaults to set
   * @param {Boolean} [force=false] - overwrite existing properties
   * @return {Object} object after applying defaults
   */
  this.defaults = function(o, d, f) {
    // if an array of defaults is given, apply them left to right
    if (Array.isArray(d)) {
      return d.reduce((o,d)=>that.defaults(o,d,f),o);
    }
    for (let k in d) {
      if ( !o.hasOwnProperty(k) ) {
        o[k] = that.deepCopy(d[k]);
      }
      // recurse, unless o[k] is an array
      else if ( !Array.isArray(o[k]) && typeof o[k] === 'object' && typeof d[k] === 'object' ) {
        that.defaults(o[k],d[k],f);
      }
      else if ( f ) {
        o[k] = that.deepCopy(d[k]);
      }
    }
    return o;
  };

  /**
   * Convert cartesian coordinates to polar coordinates
   *
   * The result array contains:
   *  * radius from the origin
   *  * angle theta in interval [0,pi], measured from the negative z-axis
   *  * angle phi in interval [-pi, pi], measured from the xz-plane
   * @param  {Number} x x coordinate
   * @param  {Number} y y coordinate
   * @param  {Number} z z coordinate
   * @return {Number[]}  Array of 3 numbers representing r, theta and phi
   */
  this.cartesianToPolar = function(x, y, z) {
    let r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    let theta = Math.acos(z / r);
    let phi = Math.atan2(y, x);
    return [r, theta, phi];
  };

  /**
 * Convert polar coordinates to cartesian coordinates
 * @param  {Number} r
 * @param  {Number} theta
 * @param  {Number} phi
 * @return {Number[]}  Array of 3 numbers representing x, y and z
 */
  this.polarToCartesian = function(r, theta, phi) {
    let x = r * Math.sin(theta) * Math.cos(phi);
    let y = r * Math.sin(theta) * Math.sin(phi);
    let z = r * Math.cos(theta);
    return [x, y, z];
  };

  /**
  * Helper for this.typeCheck
  */
  this.check = {
    any: () => true,
    string: function(v) {
      return typeof v === 'string' || v instanceof String;
    },
    number: function(v) {
      return (typeof v === 'number' || v instanceof Number) && !isNaN(v);
    },
    float: function(v) {
      return (typeof v === 'number' || v instanceof Number) && !isNaN(v);
    },
    notnegative: function(v) {
      return (typeof v === 'number' || v instanceof Number) && v >= 0;
    },
    notpositive: function(v) {
      return (typeof v === 'number' || v instanceof Number) && v <= 0;
    },
    negative: function(v) {
      return (typeof v === 'number' || v instanceof Number) && v < 0;
    },
    positive: function(v) {
      return (typeof v === 'number' || v instanceof Number) && v > 0;
    },
    factor: function(v) {
      return (typeof v === 'number' || v instanceof Number) && v >= 0 && v <= 1;
    },
    color: function(v) {
      return TOTINYCOLOR(v) !== null;
    },
    integer: function(v) {
      return (typeof v === 'number' || v instanceof Number) && !isNaN(v) && v === parseInt(v, 10);
    },
    boolean: function(v) {
      return typeof v === 'boolean' || v instanceof Boolean;
    },
    object: function(v) {
      return v !== undefined && typeof v === 'object';
    },
    function: function(v) {
      return v !== undefined && typeof v === 'function';
    },
    hexadecimal: function (v) {
      if (that.typeCheck(v,'string')){
        v = v.replace('#','');
        let a = parseInt(v,16);
        return !(!v.endsWith(a.toString(16)) || a < 0 || a > 0xffffff);
      } else if(that.typeCheck(v,'number')) {
        return !(v < 0 || v > 0xffffff);
      } else {
        return false;
      }
    },
    vector2obj: function(v) {
      return (v.x || v.x === 0) && (v.y || v.y === 0) ? that.typeCheck(v.x,'number') && that.typeCheck(v.y,'number') ? true : false : false;
    },
    vector2arr: function(v) {
      return Array.isArray(v) && v.length >= 2 ? that.typeCheck(v[0],'number') && that.typeCheck(v[1],'number') ? true : false : false;
    },
    vector2any: function(v) {
      return that.typeCheck(v,'vector2arr') || that.typeCheck(v,'vector2obj') ? true : false;
    },
    vector3obj: function(v) {
      return (v.x || v.x === 0) && (v.y || v.y === 0) && (v.z || v.z === 0) ? that.typeCheck(v.x,'number') && that.typeCheck(v.y,'number') && that.typeCheck(v.z,'number') ? true : false : false;
    },
    vector3arr: function(v) {
      return Array.isArray(v) && v.length >= 3 ? that.typeCheck(v[0],'number') && that.typeCheck(v[1],'number') && that.typeCheck(v[2],'number') ? true : false : false;
    },
    vector3any: function(v) {
      return that.typeCheck(v,'vector3arr') || that.typeCheck(v,'vector3obj') ? true : false;
    },
    matrix4x4obj: function(v) {
      return  (v.a || v.a === 0) && (v.b || v.b === 0) && (v.c || v.c === 0) && (v.d || v.d === 0) &&
              (v.e || v.e === 0) && (v.f || v.f === 0) && (v.g || v.g === 0) && (v.h || v.h === 0) &&
              (v.i || v.i === 0) && (v.j || v.j === 0) && (v.k || v.k === 0) && (v.l || v.l === 0) &&
              (v.m || v.m === 0) && (v.n || v.n === 0) && (v.o || v.o === 0) && (v.p || v.p === 0) ?
        that.typeCheck(v.a,'number') && that.typeCheck(v.b,'number') && that.typeCheck(v.c,'number') && that.typeCheck(v.d,'number') &&
        that.typeCheck(v.e,'number') && that.typeCheck(v.f,'number') && that.typeCheck(v.g,'number') && that.typeCheck(v.h,'number') &&
        that.typeCheck(v.i,'number') && that.typeCheck(v.j,'number') && that.typeCheck(v.k,'number') && that.typeCheck(v.l,'number') &&
        that.typeCheck(v.m,'number') && that.typeCheck(v.n,'number') && that.typeCheck(v.o,'number') && that.typeCheck(v.p,'number') ?
          true : false : false;
    },
    matrix4x4arr: function(v) {
      return Array.isArray(v) && v.length >= 16 ?
        that.typeCheck(v[0],'number') && that.typeCheck(v[1],'number') && that.typeCheck(v[2],'number') && that.typeCheck(v[3],'number') &&
        that.typeCheck(v[4],'number') && that.typeCheck(v[5],'number') && that.typeCheck(v[6],'number') && that.typeCheck(v[7],'number') &&
        that.typeCheck(v[8],'number') && that.typeCheck(v[9],'number') && that.typeCheck(v[10],'number') && that.typeCheck(v[11],'number') &&
        that.typeCheck(v[12],'number') && that.typeCheck(v[13],'number') && that.typeCheck(v[14],'number') && that.typeCheck(v[15],'number') ?
          true : false : false;
    },
    matrix4x4any: function(v) {
      return that.typeCheck(v,'matrix4x4arr') || that.typeCheck(v,'matrix4x4obj') ? true : false;
    },
    box3any: function(v) {
      return v.min && v.max && that.typeCheck(v.min,'vector3any') && that.typeCheck(v.max,'vector3any') ? true : false;
    },
  };

  /**
   * Function to check the input for a specific type for optional possibility to log error messages to a specified console with an optional scope.
   *
   * @param {*} v the variable which should be checked
   * @param {string} type the type to be checked for
   * @param {function} [log] an optional function in which error messages should be logged
   * @param {string} [scope] an optional scope in which to log to
   * @param {...*} [logObjs] optional further values|objects to append to log
   */
  this.typeCheck = function(v, type, log, scope, ...logObjs){
    let message, check;

    if (v === undefined) {
      message = 'The given input is undefined.';
      check = false;
    } else if (v === null) {
      message = 'The given input is null.';
      check = false;
    } else {
      type = type.toLowerCase();
      if(this.check[type]){
        check = this.check[type](v);
        message = check ? '' : 'Value ' + v + ' is not a ' + type;
      }else{
        message = 'No type check available for type ' + type + '. The possible types are [' + Object.getOwnPropertyNames(this.check).map(
          function (val) {
            return ' ' + val;
          }) + ' ]. Choose one of them.';
        check = false;
      }
    }

    if(typeof log === 'function' && check === false){
      scope ? log(scope, message, ...logObjs) : log(message, ...logObjs);
      return check;
    }
    return check;
  };

  /**
   * Returns a promise that resolves after the specified timeout
   *
   * @param {Number} timeout - the timeout to wait for in msec
   * @return {Promise}
   */
  this.waitForTimeout = function(timeout) {
    return new Promise(function(resolve) {
      setInterval(resolve, timeout);
    });
  };

  /**
   * Helper for converting to a THREE.Vector3, idempotent for THREE.Vector3.
   * Use this if you are given an object which is one of the following,
   * and you want to ensure to get back a THREE.Vector3:
   *
   *  * an array of 3 numbers
   *  * an object which has number properties x,y,z
   *  * a THREE.Vector3
   *
   * CAUTION: does not do checking for validity of v, use typeCheck if you need that
   *
   * @param {Number[]|Vector3|Object} v - the value to convert to THREE.Vector3, CAUTION no validity checking of v is done!!! Use typeCheck if you need that
   * @return {Vector3}
   */
  this.toVector3 = function(v) {
    if (v.isVector3) {
      return v;
    } else if (Array.isArray(v) && v.length >= 2) {
      let vec = new THREE.Vector3();
      vec.fromArray(v);
      return vec;
    } else {
      return new THREE.Vector3(v.x, v.y, v.z);
    }
  };

  /**
   * Given two version number strings, compare them and return whether the first one
   * represents a strictly lower version than the second one
   *
   * @param {String} v1str the first version string (e.g. '2.5.00010.101')
   * @param {String} v2str the second version string (e.g. '2.05.10.99')
   */
  this.versionStringLowerThan = function(v1str, v2str) {
    const v1arr = v1str.split('.'),
          v2arr = v2str.split('.');
    if (v1arr.length > v2arr.length) {
      for (let i=v2arr.length; i<v1arr.length; i++)
        v2arr.push('0');
    } else if (v1arr.length < v2arr.length) {
      for (let i=v1arr.length; i<v2arr.length; i++)
        v1arr.push('0');
    }
    v1arr.reverse();
    v2arr.reverse();
    let maxComponentLen = 1;
    v1arr.forEach(v => maxComponentLen = v.length > maxComponentLen ? v.length : maxComponentLen);
    v2arr.forEach(v => maxComponentLen = v.length > maxComponentLen ? v.length : maxComponentLen);
    const reducer = (acc, cur, idx) => acc + Math.pow(10,idx * maxComponentLen) * cur;
    const v1 = v1arr.reduce(reducer, 0);
    const v2 = v2arr.reduce(reducer, 0);
    return v1 < v2;
  };

  /**
   * Convert an array to an object whose properties represent array indices
   *
   * @param {Array} arr - the array to convert
   * @return {Object} object representing the array, null on error
   */
  this.ArrayToObject = function(arr) {
    if ( !Array.isArray(arr) ) return null;
    let i,
        imax = arr.length,
        o = {};
    for (i=0; i<imax; i++) {
      o['' + i] = arr[i];
    }
    return o;
  };

  /**
   * Convert an object whose properties represent array indices to an array
   *
   * @see ArrayToObject
   * @param {Object} o - the object to convert
   * @return {Array} the reconstructed array, null on error
   */
  this.ObjectToArray = function(o) {
    if ( o === null || typeof o !== 'object' ) return null;
    let i = 0,
        arr = [],
        prop;
    for (i=0; true; i++) {
      prop = '' + i;
      if ( !o.hasOwnProperty(prop) )
        break;
      arr.push( o[prop] );
    }
    return arr;
  };

  /**
   * Reference to toTinyColor
   */
  this.toTinyColor = TOTINYCOLOR;

  /**
   * Reference to atobUTF8
   */
  this.atobUTF8 = ATOBTOA.atobUTF8;

  /**
   * Reference to btoaUTF8
   */
  this.btoaUTF8 = ATOBTOA.btoaUTF8;


  return this;
};




// export the constructor
module.exports = new GlobalUtils();
